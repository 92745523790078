function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p'),_vm._v(" "),_c('h3',[_vm._v("Такса публикуване")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',[_vm._v("За да изпратят ръкопис в "),_c('strong',[_vm._v("Journal of Mountain Agriculture on the Balkans (JMAB)")]),_vm._v(", авторите трябва да платят "),_c('strong',[_vm._v("130 лева до 01.05 и 150 лева след тази дата")]),_vm._v(", след като преминат през двойно сляпо рецензиране и процес на одобрение за публикуване в JMAB.")]),_vm._v(" "),_c('p',[_vm._v("Отговорният редактор ще предостави на водещия автор PDF файл с публикувания ръкопис.")]),_vm._v(" "),_c('p'),_vm._v(" "),_c('h3',[_vm._v("Абонаментна такса")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',[_vm._v("Абонаментът за списанието е "),_c('strong',[_vm._v("90 лева")]),_vm._v(". Едно копие на всеки брой на JMAB струва 15 лева плюс банкови разходи и пощенски разходи.")]),_vm._v(" "),_c('p',[_vm._v("Таксите се внасят само по банков път по следната банкова сметка:")]),_vm._v(" "),_c('ul',[_c('li',[_vm._v("Банка: UniCredit Bulbank – Troyan")]),_vm._v(" "),_c('li',[_vm._v("IBAN: BG43UNCR75273153882301")]),_vm._v(" "),_c('li',[_vm._v("BIC: UNCRBGSF")])]),_vm._v(" "),_c('p',[_c('em',[_vm._v("Забележка: След извършване на банковия превод на таксата, моля, изпратете ни имейл с пълната информацията за фактуриране.")])])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/fees","lang":"bg","title":"Fees","header":"large"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }