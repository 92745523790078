function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p'),_vm._v(" "),_c('h3',[_vm._v("Submission fee")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',[_vm._v("To submit a manuscript in "),_c('strong',[_vm._v("Journal of Mountain Agriculture on the Balkans (JMAB)")]),_vm._v(", The regular fee for publication is "),_c('strong',[_vm._v("130 BGN until 01.05 and 150 BGN after this date")]),_vm._v(", after undergoing a double-blind peer review and approval process for publication in JMAB.")]),_vm._v(" "),_c('p',[_vm._v("The managing editor will supply the corresponding author with PDF file of the published manuscript.")]),_vm._v(" "),_c('p'),_vm._v(" "),_c('h3',[_vm._v("Subscription fee")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',[_vm._v("The Journal subscription is "),_c('strong',[_vm._v("90 BGN")]),_vm._v(". A single print copy of each issue of JMAB costs 15 BGN plus bank costs and postage.")]),_vm._v(" "),_c('p',[_vm._v("The fees must be paid via bank transfer to the following bank account:")]),_vm._v(" "),_c('ul',[_c('li',[_vm._v("Bank: UniCredit Bulbank – Troyan")]),_vm._v(" "),_c('li',[_vm._v("IBAN: BG43UNCR75273153882301")]),_vm._v(" "),_c('li',[_vm._v("BIC: UNCRBGSF")])]),_vm._v(" "),_c('p',[_c('em',[_vm._v("Note: After the bank transfer of the fee, please send us an e-mail in order to issue an invoice for the fee.")])])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/fees","lang":"en","title":"Fees","header":"large"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }